import React, {useContext} from "react";
import logo from "../images/splash-screen.png";
import MyButton from "../components/MyButton";
import {AppContext, ButtonText} from "../model/AppContext";
import PreloadImages from "../components/PreloadImages";

const StartPage: React.FC = () => {

    const {
        pageIndex,
        setPageIndex
    } = useContext(AppContext);

    const onButtonClick = (param: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setPageIndex(pageIndex + 1);
    }

    return (
        <header className="App-body">
            <img src={logo} className="splashImage" alt="logo"/>
            <p>
                <MyButton id="startButton"
                          label={ButtonText[pageIndex]}
                          onClick={onButtonClick}
                          className={"myButton pulse"}
                />
            </p>
            <PreloadImages/>
        </header>
    );
}
export default StartPage;