import React from "react";
import img from "../images/treasureChest.png";
import admiralsHat from "../images/admirals-hat.png";
import captainsHat from "../images/captains-hat.png";
import swabTheDeck from "../images/swab-the-deck.png";
import BaseIntroPage from "./BaseIntroPage";

const HowToPlay: React.FC = () => {

    return (
        <BaseIntroPage pageHeader={"Scoring"}
                       pageText={
                           <ul>
                               <li className={"listFont"}>
                                   Protect all of your treasure from the pirates to earn the rank of Admiral.&nbsp;
                                   <img className={"thumbnailImage pulse"} src={admiralsHat} alt={"admirals hat"}/>
                               </li>
                               <li className={"listFont"}>Protect some of your treasure to earn the rank of
                                   Captain.&nbsp;
                                   <img className={"thumbnailImage pulse"} src={captainsHat} alt={"captains hat"}/>
                               </li>
                               <li className={"listFont"}>Lose all of your treasure and you will have to swab the
                                   deck!&nbsp;
                                   <img className={"thumbnailImage pulse"} src={swabTheDeck} alt={"swabbing the deck"}/>
                               </li>
                           </ul>}
                       pageImageSrc={<img src={img} className="scenarioImage grow" alt="treasure chest"/>}
        />
    );
}
export default HowToPlay;