import React from "react";
import QuestPage from "../components/QuestPage";
import email from "../images/fake-store.png";


const SpoofURL: React.FC = () => {
    return (
        <QuestPage pageHeader={<span>You receive this email:<br/>
                                <img src={email} className={"grow"} height={"250px"} alt={"email"}/><br/>
                                Is this a safe link to click on?</span>}
                   goodButtonText={"There's something phishy about this link"}
                   goodResponseHeader={"Nice, you spotted the fake URL!"}
                   badButtonText={"Looks good to me!"}
                   badResponseHeader={"Oh no! It's a link to a spoof site'!"}
                   responseDescription={
                       <span>
                           At first glance this looks like a safe Amazon.com link...but the "a" in αmαzon.com is
                           actually the Greek letter alpha. Pirates use tricks like this to lure victims to spoof websites!
                       </span>
                   }
                   proTip={<span>Rather than trusting a link within an email, manually enter the URL and use
                       bookmarks to navigate to trusted sites.</span>}
        />
    );
}
export default SpoofURL;