import React from "react";
import img from "../images/parrot.png";
import BaseIntroPage from "./BaseIntroPage";


export const PAGE_ANSWERING_THE_CALL: string = "answeringTheCall";

const PlayAloud: React.FC = () => {

    return (
        <BaseIntroPage pageHeader={"Playing Aloud"}
                       pageText={<span>At each challenge, reflect on your thought process when determining your
                           choice. Explain your thinking if doing this as part of a research study. This is known as
                           playing aloud.</span>}
                       pageImageSrc={<img src={img} className="scenarioImage flyIn" alt="pirate hat"/>}
        />
    );
}
export default PlayAloud;