import React from "react";
import img from "../images/crab.png";
import BaseIntroPage from "./BaseIntroPage";


export const PAGE_ANSWERING_THE_CALL: string = "answeringTheCall";

const SideKick: React.FC = () => {

    return (
        <BaseIntroPage pageHeader={"Your Sidekick"}
                       pageText={<span>Accompanying you on your quest is Happy Crab. Happy will provide
                           insights on steering clear of internet pirates!</span>}
                       pageImageSrc={<img src={img} className="scenarioImage spin" alt="Happy the Crab"/>}
        />
    );
}
export default SideKick;