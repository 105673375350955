import React from "react";
import img from "../images/pirate.png";
import coin from "../images/coin.png";
import BaseIntroPage from "./BaseIntroPage";
import {MaxCoins} from "../App";

const HowToPlay: React.FC = () => {

    return (
        <BaseIntroPage pageHeader={"How to Play"}
                       pageText={<span>You begin your quest with {MaxCoins} gold coins&nbsp;
                           <img src={coin} height={"32px"} alt={"coin"}/>. You will face challenges
                           related to internet security and given a choice of action. Choose wisely,
                           because pirates abound in these waters trying to steal your treasure!
                       </span>}
                       pageImageSrc={<img src={img} className="scenarioImage grow" alt="pirate hat"/>}
        />
    );
}
export default HowToPlay;