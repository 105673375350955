import React from "react";
import phone from "../images/ship.png";
import BaseIntroPage from "./BaseIntroPage";

const Introduction: React.FC = () => {

    return (
        <BaseIntroPage pageHeader={"Introduction"}
                       pageText={<span>Welcome! This game is intended to be a
                           fun way of learning how to avoid Internet pirates determined to steal your treasure!</span>}
                       pageImageSrc={<img src={phone} className="scenarioImage flyIn" alt="phone"/>}
        />
    );
}
export default Introduction;