import {createContext, ReactNode} from "react";
import HowToPlay from "../intro/HowToPlay";
import Introduction from "../intro/Introduction";
import PlayAloud from "../intro/PlayAloud";
import AnsweringTheCall from "../quests/AnsweringTheCall";
import BankNotification from "../quests/BankNotification";
import EndQuest from "../quests/EndQuest";
import StartPage from "../quests/StartPage";
import Scoring from "../intro/Scoring";
import SocialMedia from "../quests/SocialMedia";
import SpoofURL from "../quests/SpoofURL";
import SideKick from "../intro/SideKick";
import VirusDetection from "../quests/VirusDetection";

export const PageSequence: ReactNode[] = [
    <StartPage/>,
    <Introduction/>,
    <HowToPlay/>,
    <Scoring/>,
    <PlayAloud/>,
    <SideKick/>,
    <VirusDetection/>,
    <AnsweringTheCall/>,
    <BankNotification/>,
    <SocialMedia/>,
    <SpoofURL/>,
    <EndQuest/>
];

export const QuestCount: number = 5;

export const ButtonText: string[] = [
    "Click Here to Start!",
    "Next: How to Play",
    "Next: Scoring",
    "Next: Playing Aloud",
    "Next: Your Sidekick",
    "Begin the Quest!"
];

export const AppContext = createContext({
    pageIndex: 0,
    setPageIndex: (idx: number): void => {
    },
    score: 0,
    setScore: (score: number): void => {
    },
    showHeader: false,
    setShowHeader: (enable: boolean): void => {
    },
    showCredits: false,
    setShowCredits: (enable: boolean): void => {
    }
});