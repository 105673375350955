import {Button} from "@mui/material";
import "../common.css";

interface Props {
    id: string,
    label: string;
    className?: string;
    onClick: (param: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}


const MyButton: React.FC<Props> = (props) => {
    return (
        <Button className={props.className || "myButton"}
            id={props.id}
                onClick={event => props.onClick(event)}
        >
            {props.label}
        </Button>
    );
}
export default MyButton;