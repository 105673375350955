import React from "react";
import socialMediaPost from "../images/tabletComputer.png";
import QuestPage from "../components/QuestPage";

const SocialMedia: React.FC = () => {
    return (
        <QuestPage pageHeader={<span>This post appears on your social media page:<br/>
            <img src={socialMediaPost} className={"grow"} height={"350px"} alt={"Social media post"}/><br/>
            What do you do?</span>}
                   goodButtonText={"I'll skip over it"}
                   badButtonText={"Reply. I love these kinds of posts!"}
                   goodResponseHeader={"Wise choice."}
                   badResponseHeader={"Oh no! Your just gave the pirates important personal details!"}
                   responseDescription={<span>Pirates use this kind of "fun" post for future raids. They can use the
                       information you provide to perform "spear-phishing" attacks, which are scam texts or emails
                       coming from someone who seems to know you. Personal details like these are also
                       used for identification when resetting a password and can be utilized by hackers.
        </span>}
                   proTip={<span>It's best to steer clear of such "fun" online activities. Keep it for in-person
                       conversations among friends!
        </span>}
        />
    );
}
export default SocialMedia;