import React from "react";
import textMessage from "../images/textMessage.png";
import QuestPage from "../components/QuestPage";

const BankNotification: React.FC = () => {
    return (
        <QuestPage pageHeader={<span>You get this text message from your bank:<br/>
            <img src={textMessage} className={"grow"} height={"350px"} alt={"text message"}/><br/>
            What do you do?</span>}
                   goodButtonText={"Check my Account"}
                   badButtonText={"Ignore the Text Message"}
                   goodResponseHeader={"You made the right decision. This really was an urgent message from your bank!"}
                   badResponseHeader={"Oh no! This was really an urgent message from your bank!"}
                   responseDescription={<span>Be sure to pay attention to notifications from your bank and other
                       financial institutions. It's important to be able to spot the real ones. Watch out for pirates
                       trying to get you to click a link or call a number that directs you to fraudulent sites.</span>}
                   proTip={<span>Legitimate alerts typically instruct you to take action through trusted sites, apps, and
                       verifiable phone numbers. </span>}
        />
    );
}
export default BankNotification;