import React, {ReactNode, useContext, useEffect, useState} from "react";
import MyButton from "../components/MyButton";
import {AppContext} from "../model/AppContext";
import {Dialog, DialogContent} from "@mui/material";
import badImg from "../images/jolly.png";
import goodImg from "../images/celebrate.png";
import img from "../images/crab.png";

interface Props {
    pageHeader: ReactNode,
    pageImageSrc?: ReactNode,
    goodButtonText: string,
    goodResponseHeader: ReactNode,
    badButtonText: string,
    badResponseHeader: ReactNode,
    responseDescription: ReactNode,
    proTip: ReactNode,
}

const QuestPage: React.FC<Props> = (props: Props) => {

    const {
        setShowHeader,
        score,
        setScore,
        pageIndex,
        setPageIndex,
    } = useContext(AppContext)

    const badImage: ReactNode = <img src={badImg} className="modalImage growThenDisappear" alt="jolly roger"/>
    const goodImage: ReactNode = <img src={goodImg} className="modalImage growThenDisappear" alt="fireworks"/>

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalHeader, setModalHeader] = useState<ReactNode>();
    const [modalImage, setModalImage] = useState<ReactNode>();
    const [displayButtonList, setDisplayButtonList] = useState<ReactNode[]>([]);

    const onBadAnswer = () => {
        let coinMsg = "";
        if (score > 0) {
            setScore(score - 1);
            coinMsg = "Sorry, you lost a coin!"
        }
        setModalHeader(<p className={"alert"}>{props.badResponseHeader} {coinMsg}</p>)
        setModalImage(badImage);
        setOpenModal(true);
    }

    const onGoodAnswer = () => {
        setModalHeader(<p className={"good"}>{props.goodResponseHeader} You protected your treasure!</p>);
        setModalImage(goodImage);
        setOpenModal(true);
    }

    const handleClose = () => {
        setOpenModal(false);
        setPageIndex(pageIndex + 1);
    }

    const buttonList: ReactNode[] = [
        <MyButton id={"badButton"} label={props.badButtonText} onClick={onBadAnswer}/>,
        <MyButton id={"goodButton"} label={props.goodButtonText} onClick={onGoodAnswer}/>
    ];

    const shuffleButtonList = () => {
        let randIdx = (Math.round(Math.random()));
        let tempList: ReactNode[] = [];
        tempList.push(buttonList[randIdx]);
        tempList.push(buttonList[randIdx === 0 ? 1 : 0]);
        setDisplayButtonList(tempList);
    }

    useEffect(() => {
        shuffleButtonList();
        // eslint-disable-next-line
    }, [props.goodButtonText]);

    useEffect(() => {
        setShowHeader(true);
    });

    return (
        <div className="App-body">
            <header className="App-body">
                <p className={"pageText alignCenter"}>{props.pageHeader}</p>
                {props?.pageImageSrc}
                <p>
                    {displayButtonList[0]}
                    &nbsp;
                    &nbsp;
                    {displayButtonList[1]}
                </p>
            </header>
            <Dialog open={openModal}
                    onClose={handleClose}
                    className={"outerModal"}
                    sx={{
                        alignItems: 'center',
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "100%",
                                maxWidth: "90vw",
                                minWidth: "50vw",
                                maxHeight: "90vh",
                                minHeight: "30vh",

                                style: {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none'
                                },
                                margin: 0
                            }
                        }
                    }}
            >
                <DialogContent className={"modal"}>
                    <DialogContent className={"modalHeaderText"}>
                        <table width={"100%"}>
                            <tbody>
                            <tr>
                                <td>{modalImage}</td>
                                <td>{modalHeader}</td>
                                <td>{modalImage}</td>
                            </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogContent className={"modalText"}><span
                        className={"bullet"}>What's the risk?</span> {props.responseDescription}
                    </DialogContent>
                    <DialogContent className={"modalText"}>
                        <span className={"bullet"}><img src={img} height={50} className={"spin"} alt="Happy the Crabhat"/>&nbsp;Happy says:</span> {props.proTip}
                    </DialogContent>
                    <DialogContent className={"modalText alignCenter"}>
                        <MyButton id={"closeDialog"} label={"Continue the Quest!"} onClick={handleClose}/>
                    </DialogContent>
                </DialogContent>
            </Dialog>

        </div>
    );
}
export default QuestPage;