import React, {ReactNode, useContext} from "react";
import img from "../images/crab.png";
import {AppContext} from "../model/AppContext";
import {MaxCoins} from "../App";
import admiralsHat from "../images/admirals-hat.png";
import captainsHat from "../images/captains-hat.png";
import swabTheDeck from "../images/swab-the-deck.png";


const EndQuest: React.FC = () => {
    const {
        score,
    } = useContext(AppContext);

    let rankImage: ReactNode = undefined;
    const getEndMessage = (): ReactNode => {
        if (score === MaxCoins) {
            rankImage = <img src={admiralsHat} className="scenarioImage grow" height={25} alt="rankImage"/>;
            return <span>You protected all of your treasure from the pirates<br/>and earned the rank of Admiral!</span>;
        }
        if (score === 0) {
            rankImage = <img src={swabTheDeck} className="scenarioImage grow" height={25} alt="rankImage"/>;
            return <span>Sorry, you lost all of your treasure to the pirates!<br/>I guess you'll have to swab the deck now!</span>;
        }
        rankImage = <img src={captainsHat} className="grow" height={200} alt="rankImage"/>;
        return <span>You protected some of your treasure from the pirates<br/>and earned the rank of Captain!</span>;
    }

    return (
        <div className="App-body">
            <span className={"pageText"}>
                {getEndMessage()}<br/>
                {rankImage}<br/>

                Be sure to keep Happy's advice in mind as you navigate<br/>the pirate-infested waters of the Internet!<br/>
                <img src={img} className="spin" height={200} alt="Happy the Crabhat"/>
            </span>
        </div>
    );
}
export default EndQuest;