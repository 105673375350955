import React, {JSX, useEffect, useState} from 'react';
import './common.css';

import {AppContext, PageSequence, QuestCount} from "./model/AppContext";
import MyButton from "./components/MyButton";
import coin from "./images/coin.png";
import lostCoin from "./images/lostCoin.png";
import CreditsPage from "./intro/CreditsPage";

export const MaxCoins: number = QuestCount;
const App: React.FC = () => {

    const [pageIndex, setPageIndex] = useState<number>(0);
    const [score, setScore] = useState<number>(MaxCoins);
    const [showHeader, setShowHeader] = useState<boolean>(false);
    const [showCredits, setShowCredits] = useState<boolean>(false);


    const doEndQuest = () => {
        setPageIndex(0);
        setScore(MaxCoins);
        setShowHeader(false);
    }

    const showCoins = (): JSX.Element[] => {
        let coins: JSX.Element[] = [];
        for (let idx: number = score; idx > 0; idx--) {
            coins.push(<img src={coin} className={"coin"} alt={"coin"}/>)
        }
        for (let idx: number = MaxCoins - score; idx > 0; idx--) {
            coins.push(<img src={lostCoin} className={"coin"} alt={"coin"}/>)
        }

        return coins;
    }

    return (
        <div className={"App"}>
            <AppContext.Provider value={(
                {
                    pageIndex,
                    setPageIndex,
                    score,
                    setScore,
                    showHeader,
                    setShowHeader,
                    showCredits,
                    setShowCredits
                }
            )}>
                <div className={"pageHeader"}>
                    {showHeader &&
                        <table className={"headerTable"}>
                            <tbody>
                            <tr>
                                <td className={"alignLeft"}>
                                    {showCoins()}
                                </td>
                                <td className={"alignRight"}><MyButton id={"endQuest"}
                                                                       label={"End the Quest"}
                                                                       onClick={doEndQuest}
                                                                       className={"creditsButton"}
                                /></td>
                            </tr>
                            </tbody>
                        </table>
                    }
                </div>
                <div className="App-body">
                    {PageSequence[pageIndex]}
                </div>

                <table className={"footer"}>
                    <tbody>
                    <tr>
                        <td className={"alignLeft"}>Beware of Pirates! A Learning Game</td>
                        <td className={"alignRight"}>
                            <CreditsPage/>
                            <MyButton id={"creditsPage"}
                                      label={"View Site Credits"}
                                      onClick={() => setShowCredits(true)}
                                      className={"creditsButton"}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </AppContext.Provider>
        </div>

    );
}
export default App;
