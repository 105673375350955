import React from "react";
import textMessage from "../images/virus-warning.png";
import QuestPage from "../components/QuestPage";

const VirusDetection: React.FC = () => {
    return (
        <QuestPage pageHeader={<span>While browsing the internet, this message pops up:<br/>
            <img src={textMessage} className={"grow"} height={"350px"} alt={"virus warning"}/><br/>
            What do you do?</span>}
                   goodButtonText={"Close my browser and run a virus scan"}
                   badButtonText={"Click the \"X\" in the upper right corner to ignore it"}
                   goodResponseHeader={"You chose wisely!"}
                   badResponseHeader={"Oh no! You may really have a virus now."}
                   responseDescription={<span>This was a fake virus warning, and even clicking on the "x" could launch
                                              a real virus attack. The popup may also indicate there is real malware on
                                              your computer.</span>}
                   proTip={<span>Whether the alert is real or a scam, if you receive such a message you should
                                 close your browser, clear your browser cache, and run a full antivirus scan.</span>}
        />
    );
}
export default VirusDetection;