import React, {ReactNode, useContext} from "react";
import MyButton from "../components/MyButton";
import {AppContext, ButtonText} from "../model/AppContext";


interface Props {
    pageHeader: string,
    pageText: ReactNode,
    pageImageSrc: ReactNode,
}

const BaseIntroPage: React.FC<Props> = (props: Props) => {

    const {
        pageIndex,
        setPageIndex
    } = useContext(AppContext)


    const onNext = () => {
        setPageIndex(pageIndex+1);
    }

    return (
        <>
            <div className="introBody">
                <header className="App-body">
                    <span className={"titleText alignCenter"}>{props.pageHeader}</span>
                    <p className={"pageText alignCenter"}>{props.pageText}</p>
                    {props.pageImageSrc}
                    <p>
                        <MyButton className={"myButton"}
                                  id={"nextButton"}
                                  label={ButtonText[pageIndex]}
                                  onClick={onNext}
                        />
                    </p>
                </header>
            </div>
        </>
    );
}
export default BaseIntroPage;