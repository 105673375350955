import React, {useContext} from "react";
import {Dialog, DialogContent} from "@mui/material";
import MyButton from "../components/MyButton";
import {AppContext} from "../model/AppContext";

const CreditsPage: React.FC = () => {
    const {
        showCredits,
        setShowCredits
    } = useContext(AppContext);

    return (
        <>
            <Dialog open={showCredits}
                    onClose={() => setShowCredits(false)}
                    className={"outerModal"}
                    sx={{
                        alignItems: 'center',
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "90%",
                                maxWidth: "95vw",
                                minWidth: "40vw",
                                maxHeight: "90vh",
                                minHeight: "30vh",
                                style: {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none'
                                },
                                margin: 0
                            }
                        }
                    }}
            >
                <DialogContent className={"modal"}>
                    <DialogContent className={"modalHeaderText"}>{"Site Credits"}</DialogContent>
                    <DialogContent className={"modalText"}>
                        <li>This site is for educational and research purposes only.<br/></li>
                        <li>All images used unless otherwise specified are downloaded from
                            <a href="https://www.pixabay.com" target="_blank" rel={"noreferrer"}>Pixabay.com</a> for
                            royalty-free use
                            under the <a href="https://pixabay.com/service/license-summary/" target="_blank"
                                         rel={"noreferrer"}>
                                Pixabay Content License</a>. Creator credits are as follows:
                        </li>
                        <li>&nbsp;&nbsp;- Pirate ship, Parrot, Fireworks, Treasure Chest, and Gold Coin images by <a
                            href="https://pixabay.com/users/openclipart-vectors-30363/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2028575"
                            target="_blank" rel={"noreferrer"}>Open Clipart Vectors</a>
                        </li>
                        <li>&nbsp;&nbsp;- Pirate image by <a
                            href="https://pixabay.com/users/15profirene-4770972/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=5544931"
                            target="_blank" rel={"noreferrer"}>Irene
                            Freitas Freitas</a>
                        </li>
                        <li>&nbsp;&nbsp;- Jolly Roger, Text Bubble, and Mobile Phone images by <a
                            href="https://pixabay.com/users/clker-free-vector-images-3736/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=47705"
                            target="_blank" rel={"noreferrer"}>Clker-Free-Vector-Images</a>
                        </li>
                        <li>&nbsp;&nbsp;- Tablet computer image by <a
                            href="https://pixabay.com/users/msmatthewslilclassroom-6302962/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=5438271"
                            target="_blank" rel={"noreferrer"}>Christie Matthews </a>
                        </li>
                        <li>&nbsp;&nbsp;- Fake Online Store Symbol image by <a
                            href="https://pixabay.com/users/kirstyfields-4444715/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=2183855"
                            target="_blank" rel={"noreferrer"}>kirstyfields</a>
                        </li>
                        <li>&nbsp;&nbsp;- Crabby the Crab Image by <a
                            href="https://pixabay.com/users/magnificopixel-4671257/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=7144038"
                            target="_blank" rel={"noreferrer"}>magnificopixel</a>
                        </li>
                        <li>Virus Warning Image downloaded from <a
                            href="https://www.avg.com/en/signal/spot-fake-virus-warning"
                            target="_blank" rel={"noreferrer"}>AVG.com</a>
                        </li>
                        <li>Admirals Hat, Captains Hat, and Penguin Swabbing the Deck Created by Copilot (OpenAI).</li>
                    </DialogContent>
                    <DialogContent className={"modalText alignCenter"}>
                        <MyButton id={"closeDialog"} label={"Close"} onClick={() => setShowCredits(false)}/>
                    </DialogContent>
                </DialogContent>
            </Dialog>
        </>
    );
}
export default CreditsPage;