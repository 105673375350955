import React from "react";
import phone from "../images/phone.png";
import QuestPage from "../components/QuestPage";


const AnsweringTheCall: React.FC = () => {
    return (
        <QuestPage pageHeader={<span>Your phone rings and you don't recognize the number.<br/>What do you do?</span>}
                   pageImageSrc={<img src={phone} className="scenarioImage buzz" alt="phone"/>}
                   goodButtonText={"Let it Go to Voicemail"}
                   goodResponseHeader={"Good call!"}
                   responseDescription={"Unsolicited phone calls are a common way pirates find their victims. It's best to let such calls go to voicemail."}
                   badButtonText={"Answer the Call"}
                   badResponseHeader={"Oh no! It was a pirate calling!"}
                   proTip={"Keep all important contact numbers in your mobile phone's address book and set your phone to send calls not in your address book directly to voicemail."}
        />
    );
}
export default AnsweringTheCall;