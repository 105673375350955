import React, {ReactNode} from "react";
import admiralsHat from "../images/admirals-hat.png";
import captainsHat from "../images/captains-hat.png";
import celebrate from "../images/celebrate.png";
import coin from "../images/coin.png";
import crab from "../images/crab.png";
import fakeStore from "../images/fake-store.png";
import jolly from "../images/jolly.png";
import lostCoin from "../images/lostCoin.png";
import parrot from "../images/parrot.png";
import phone from "../images/phone.png";
import pirate from "../images/pirate.png";
import ship from "../images/ship.png";
import splashScreen from "../images/splash-screen.png";
import swabTheDeck from "../images/swab-the-deck.png";
import tabletComputer from "../images/tabletComputer.png";
import textBubble from "../images/textBubble.png";
import textMessage from "../images/textMessage.png";
import treasureChest from "../images/treasureChest.png";

const PreloadImages: React.FC = () => {

    const imageList: string[] = [
        admiralsHat,
        captainsHat,
        celebrate,
        coin,
        crab,
        fakeStore,
        jolly,
        lostCoin,
        parrot,
        phone,
        pirate,
        ship,
        splashScreen,
        swabTheDeck,
        tabletComputer,
        textBubble,
        textMessage,
        treasureChest
    ];

    const getImages = (): ReactNode[] => {
        let images: ReactNode[] = [];
        imageList.forEach(value => {
            images.push(<img src={value} width={0} alt={value}/>);
        })

        return images;
    }

    return (
        <>{getImages()}</>
    );

};
export default PreloadImages;